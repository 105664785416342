<template>
  <vx-card
    :title="
      this.surveyAllocationID
        ? 'Edit Survey Allocation'
        : 'Create Survey Allocation'
    "
  >
    <div class="vx-row mb-6" style="width: 50%">
      <vs-button
        class="ml-4 mt-2"
        color="danger"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="handleBack()"
        >Back</vs-button
      >
    </div>
    <div class="vx-row mb-6" style="width: 50%" v-if="this.surveyAllocationID">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Code</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input :value="this.code" :readonly="true"></vs-input>
      </div>
    </div>
    <!-- <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Name</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input v-model="name" class="w-full" />
      </div>
    </div> -->

    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Description</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-textarea v-model="description" width="100%" />
      </div>
    </div>

    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Valid From - To</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <date-range-picker
          style="min-height: 40px"
          class="w-full"
          ref="picker"
          opens="center"
          :locale-data="{ firstDay: 1, format: 'dd Mon yyyy' }"
          :singleDatePicker="false"
          :timePicker="false"
          :timePicker24Hour="false"
          :showWeekNumbers="false"
          :showDropdowns="false"
          :autoApply="true"
          v-model="validity"
          :linkedCalendars="true"
        >
          <template v-slot:input="picker" style="min-width: 350px">
            {{ dateFormat(picker.startDate) }} -
            {{ dateFormat(picker.endDate) }}
          </template>
        </date-range-picker>
      </div>
    </div>

    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Survey Catalog</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select
          multiple
          :filterable="false"
          :options="optionSurveyCatalog"
          v-model="selectedSurveyCatalog"
          @search="
            (search) => {
              getSurveyCatalog(search);
            }
          "
        />
      </div>
    </div>

    <!-- <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Valid From</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <datepicker
          name="date"
          :inline="false"
          v-model="validFrom"
          placeholder="Select Date"
          v-validate="'required'"
        ></datepicker>
      </div>
    </div>

    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Valid To</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <datepicker
          name="date"
          :inline="false"
          v-model="validTo"
          placeholder="Select Date"
          v-validate="'required'"
        ></datepicker>
      </div>
    </div> -->
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Territory</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select
          multiple
          :filterable="true"
          :options="optionTerritory"
          v-model="selectedTerritory"
        />
      </div>
    </div>

    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Work Position Type</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select
          multiple
          :filterable="true"
          :options="optionWorkPositionType"
          v-model="selectedWorkPositionType"
        />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Work Position Subtype</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select
          multiple
          :filterable="true"
          :options="optionWorkPositionSubtype"
          v-model="selectedWorkPositionSubtype"
        />
      </div>
    </div>

    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Salesman</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select
          multiple
          :filterable="false"
          :options="optionPersonal"
          v-model="selectedPersonal"
          @search="
            (search, loading) => {
              onSearchPersonal(search, loading);
            }
          "
        />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Status</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <input type="radio" id="active" value="Active" v-model="status" />
        <label for="active">Active</label>

        <input type="radio" id="inactive" value="Inactive" v-model="status" />
        <label for="inactive">Inactive</label>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-button v-if="this.surveyAllocationID ? hasPermission('edit') : hasPermission('create')" class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleBack"
          >Back</vs-button
        >
      </div>
    </div>
  </vx-card>
</template>
<script>
import ArrowUpDownBold from "vue-material-design-icons/ArrowUpDownBold.vue";
import PlusBox from "vue-material-design-icons/PlusBox.vue";

import draggable from "vuedraggable";

import Datepicker from "vuejs-datepicker";
import moment from "moment";
import vSelect from "vue-select";
import _ from "lodash";

import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  components: {
    draggable,
    ArrowUpDownBold,
    PlusBox,
    Datepicker,
    vSelect,
    DateRangePicker,
  },
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        baseUrl: "/api/sfa/v1/survey-allocation",
        code: "",
        name: "-",
        description: "",
        validFrom: null,
        validTo: null,
        validity: {
          startDate: null,
          endDate: null,
        },
        minValidFrom: null,
        maxValidTo: null,
        status: "Active",
        personalIDs: [],
        surveyAllocationID: null,
        optionTerritory: [],
        selectedTerritory: [],
        optionPersonal: [],
        selectedPersonal: [],
        optionSurveyCatalog: [],
        selectedSurveyCatalog: [],
        optionWorkPositionType: [],
        selectedWorkPositionType: [],
        optionWorkPositionSubtype: [],
        selectedWorkPositionSubtype: [],
      };
    },
    handleBack() {
      this.$router.push({
        name: "survey-allocation",
      });
    },
    handleSubmit() {
      if (!this.name) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Name is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }

      if (this.selectedSurveyCatalog.length == 0) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Survey Catalog is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }

      if (!this.validity.startDate || !this.validity.endDate) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Valid from and valid to is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }

      if (this.validity.startDate && this.validity.endDate) {
        if (
          this.validity.startDate < this.minValidFrom ||
          this.validity.endDate > this.maxValidTo
        ) {
          let within =
            " (" +
            moment(this.minValidFrom).format("DD MMM YYYY") +
            " - " +
            moment(this.maxValidTo).format("DD MMM YYYY") +
            ")";

          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "Valid from - to is required within" + within,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return false;
        }
      }

      let territoryIDs = [];
      this.selectedTerritory.forEach((element) => {
        territoryIDs.push(element.id);
      });

      let typeWorkPositionIDs = [];
      this.selectedWorkPositionType.forEach((d) => {
        typeWorkPositionIDs.push(d.id);
      });

      let subtypeWorkPositionIDs = [];
      this.selectedWorkPositionSubtype.forEach((d) => {
        subtypeWorkPositionIDs.push(d.id);
      });

      let personalIDs = [];
      this.selectedPersonal.forEach((d) => {
        personalIDs.push(d.personal_id);
      });

      let surveyCatalogIDs = [];
      this.selectedSurveyCatalog.forEach((d) => {
        surveyCatalogIDs.push(d.id);
      });

      let body = {
        name: this.name,
        description: this.description,
        status: this.status,
        valid_from: this.validity.startDate
          ? moment(this.validity.startDate).format("YYYY-MM-DD")
          : null,
        valid_to: this.validity.endDate
          ? moment(this.validity.endDate).format("YYYY-MM-DD")
          : null,
        territory_ids: territoryIDs,
        type_work_position_ids: typeWorkPositionIDs,
        subtype_work_position_ids: subtypeWorkPositionIDs,
        personal_ids: personalIDs,
        survey_catalog_ids: surveyCatalogIDs,
      };

      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          let res = null;
          if (this.surveyAllocationID) {
            res = this.$http.put(
              this.baseUrl + "/" + this.surveyAllocationID + "/update",
              body
            );
          } else {
            res = this.$http.post(this.baseUrl + "/create", body);
          }

          res
            .then((resp) => {
              if (resp.code == 200) {
                this.$vs.notify({
                  color: "success",
                  title: "Success",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });

                this.$vs.loading.close();
                setTimeout(this.handleBack(), 2000);
              } else {
                this.$vs.loading.close();
                this.$vs.notify({
                  color: "danger",
                  title: "Error",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              console.log(error);
            });
        }
      });
    },
    getSurveyAllocation() {
      if (this.surveyAllocationID) {
        this.$vs.loading();
        this.$http
          .get(this.baseUrl + "/" + this.surveyAllocationID + "/edit")
          .then((resp) => {
            if (resp.code == 200) {
              this.code = resp.data.code;
              this.name = resp.data.name;
              this.description = resp.data.description;
              this.status = resp.data.status;
              this.validity.startDate = new Date(resp.data.valid_from);
              this.validity.endDate = new Date(resp.data.valid_to);
              this.selectedTerritory = resp.data.territories;
              this.selectedWorkPositionType = resp.data.type_work_positions;
              this.selectedWorkPositionSubtype = resp.data.subtype_work_positions;
              this.selectedPersonal = resp.data.personals;
              this.selectedSurveyCatalog = resp.data.survey_catalogs;
            }
            this.$vs.loading.close();
          });
      }
    },
    getSurveyCatalog(search) {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/survey-catalog", {
          params: {
            length: 0,
            order: "name",
            sort: "asc",
            search: search,
            date_now: moment().format("YYYY-MM-DD"),
            valid_from: this.validity.startDate
              ? moment(this.validity.startDate).format("YYYY-MM-DD")
              : "",
            valid_to: this.validity.endDate
              ? moment(this.validity.endDate).format("YYYY-MM-DD")
              : "",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionSurveyCatalog = resp.data.records;
            } else {
              this.optionSurveyCatalog = [];
              this.selectedSurveyCatalog = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getTerritory() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/territory", {
          params: {
            length: 0,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionTerritory = resp.data.records;
            } else {
              this.optionTerritory = [];
              this.selectedTerritory = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },

    getWorkPosition(type) {
      var t = this
       var typeWorkPositionIDs = [];
      t.selectedWorkPositionType.forEach((element) => {
        typeWorkPositionIDs.push(element.id);
      });
       t.$http
        .get(t.baseUrl + "/work-position", {
          params: {
            length: 999999,
            // search: search.trim(),
            parent_work_position_ids: typeWorkPositionIDs,
            type: type,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              if (type == "type") {
                t.optionWorkPositionType = resp.data.records;
              } else if (type == "subtype") {
                t.optionWorkPositionSubtype = resp.data.records;
              }
            } else {
              if (type == "type") {
                t.optionWorkPositionType = [];
              } else if (type == "subtype") {
                t.optionWorkPositionSubtype = [];
              }
            }
            // loading(false);
          } else {
            if (type == "type") {
                t.optionWorkPositionType = [];
              } else if (type == "subtype") {
                t.optionWorkPositionSubtype = [];
              }
            // loading(false);
          }
        });

    },
    onSearchWorkPosition(search, loading, type) {
      if (search.length) {
        loading(true);
        this.searchWorkPosition(loading, search, this, type);
      }
    },
    searchWorkPosition: _.debounce((loading, search, t, type) => {
      var typeWorkPositionIDs = [];
      t.selectedWorkPositionType.forEach((element) => {
        typeWorkPositionIDs.push(element.id);
      });

      t.$http
        .get(t.baseUrl + "/work-position", {
          params: {
            length: 999999,
            search: search.trim(),
            parent_work_position_ids: typeWorkPositionIDs,
            type: type,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              if (type == "type") {
                t.optionWorkPositionType = resp.data.records;
              } else if (type == "subtype") {
                t.optionWorkPositionSubtype = resp.data.records;
              }
            } else {
              if (type == "type") {
                t.optionWorkPositionType = [];
              } else if (type == "subtype") {
                t.optionWorkPositionSubtype = [];
              }
            }
            loading(false);
          } else {
            if (type == "type") {
                t.optionWorkPositionType = [];
              } else if (type == "subtype") {
                t.optionWorkPositionSubtype = [];
              }
            loading(false);
          }
        });
    }, 350),

    onSearchPersonal(search, loading) {
      if (search.length) {
        loading(true);
        this.searchPersonal(loading, search, this);
      }
    },
    searchPersonal: _.debounce((loading, search, t) => {
      var territorryIDs = [];
      var typeWorkPositionIDs = [];
      var subtypeWorkPositionIDs = [];
      t.selectedTerritory.forEach((element) => {
        territorryIDs.push(element.id);
      });

      t.selectedWorkPositionType.forEach((element) => {
        typeWorkPositionIDs.push(element.id);
      });

      t.selectedWorkPositionSubtype.forEach((element) => {
        subtypeWorkPositionIDs.push(element.id);
      });

      t.$http
        .get(t.baseUrl + "/sales", {
          params: {
            length: 10,
            search: search.trim(),
            territory_ids: territorryIDs,
            type_work_position_ids: typeWorkPositionIDs,
            subtype_work_position_ids: subtypeWorkPositionIDs,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
                t.optionPersonal = resp.data.records;
            } else {
                t.optionPersonal = [];
            }
            loading(false);
          } else {
            t.optionPersonal = [];
            loading(false);
          }
        });
    }, 350),

    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("DD MMM YYYY");
      }
      return a;
    },
  },
  created() {},
  mounted() {
    this.$store.dispatch("user/getPermissions", "survey-allocation");
    // this.getSurveyCatalog();
    this.getTerritory();
    this.surveyAllocationID = this.$route.params.id;
    if (this.surveyAllocationID) {
      this.getSurveyAllocation();
    }

    this.getWorkPosition("type")
  },
  watch: {
    validity: {
      handler: function (val) {
        // console.log(val);
        this.getSurveyCatalog("");
      },
      deep: true,
    },
    selectedSurveyCatalog: {
      handler: function (val) {
        val.forEach((d) => {
          var surveyCatalogValidFrom = new Date(d.valid_from);
          var surveyCatalogValidTo = new Date(d.valid_to);

          if (
            this.minValidFrom == null ||
            this.minValidFrom > surveyCatalogValidFrom
          ) {
            this.minValidFrom = surveyCatalogValidFrom;
            if (
              this.validity.startDate == null ||
              this.validity.startDate < this.minValidFrom
            ) {
              this.validity.startDate = surveyCatalogValidFrom;
            }
          }

          if (
            this.maxValidTo == null ||
            this.maxValidTo < surveyCatalogValidTo
          ) {
            this.maxValidTo = surveyCatalogValidTo;
            if (
              this.validity.endDate == null ||
              this.validity.endDate > this.maxValidTo
            ) {
              this.validity.endDate = surveyCatalogValidTo;
            }
          }
        });
        // console.log(val);
      },
      deep: true,
    },
    selectedTerritory(val) {
      var indexSelectedPersonalToRemove = [];
      val.forEach((element) => {
        this.selectedPersonal.forEach((val, indexPersonal) => {
          var territorryIDs = JSON.parse(val.territory_ids);
          if (!territorryIDs.includes(element.id)) {
            if (!indexSelectedPersonalToRemove.includes(indexPersonal)) {
              indexSelectedPersonalToRemove.push(indexPersonal);
            }
          }
        });
      });

      indexSelectedPersonalToRemove.forEach((val) => {
        this.selectedPersonal.splice(val, 1);
      });
    },

    selectedWorkPositionType(val) {
      this.getWorkPosition("subtype")
      var parentWorkPositionIDs = []
      val.forEach((element) => {
        parentWorkPositionIDs.push(element.id)
      });


      var indexSelectedWorkPositionSubtypeToRemove = [];
      this.selectedWorkPositionSubtype.forEach((val, index) => {
            if (!parentWorkPositionIDs.includes(val.parent_id)) {
            if (!indexSelectedWorkPositionSubtypeToRemove.includes(index)) {
              indexSelectedWorkPositionSubtypeToRemove.push(index);
            }
          }
      });

      indexSelectedWorkPositionSubtypeToRemove.forEach((val) => {
        this.selectedWorkPositionSubtype.splice(val, 1);
      });
    },

    selectedWorkPositionSubtype(val) {
      var indexSelectedPersonalToRemove = [];
      val.forEach((element) => {
         this.selectedPersonal.forEach((val, indexPersonal) => {
           var workPositionIDs = JSON.parse(val.work_position_ids);
          if (!workPositionIDs.includes(element.id)) {
            if (!indexSelectedPersonalToRemove.includes(indexPersonal)) {
              indexSelectedPersonalToRemove.push(indexPersonal);
            }
          }
        });
      });

      indexSelectedPersonalToRemove.forEach((val) => {
        this.selectedPersonal.splice(val, 1);
      });
    },
  },
};
</script>

